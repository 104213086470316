html {
    scroll-behavior: smooth;
    height: 100%;
}

body {
    margin: 0;
    --primary1: #ffffff;
    --primary2: #f0f0f0;
    --secondary: #3d5a7f;
    --transparentsecondary: rgb(61, 90, 127, 0.04);
    --secondary2: #98c1d9;
    --accent: #293241;
    --contrast: #ee6c4d;
    --defaultGrey: #d6d6d6;
    --secondaryHover: #4b6c9b;
    --secondaryRipple: hsl(214, 35%, 53%);
    --greyHover: #ebebeb;
    --disabledGrey: #a0a0a0;
    --outlineGrey: #b8b8b8;
    --greyRipple: #ffffff;
    --greyFont: #787878;
    --greyBtn: #afafaf;
    --light-grey: #d6d6d6;
    --dark-grey: #4f4f4f;
    --error: #cc2936;
    color: var(--accent);
    scrollbar-width: none;
    overflow: overlay;
    font-family: 'Nunito';
    /* Style to remove space between inline-block elements */
    unicode-bidi: embed;
    height: 100%;
}
